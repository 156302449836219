import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { requestAuth } from './asyncActions';

import { AuthSliceState } from './types';
import { Status } from '../../types/types';


const initialState: AuthSliceState = {
    message: '',
    statusAuth: Status.CALM as Status,
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setMessage(state, action: PayloadAction<string>) {
            state.message = action.payload;
        },
        setStatus(state, action: PayloadAction<Status>) {
            state.statusAuth = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestAuth.pending, (state) => {
            state.statusAuth = Status.LOADING;
        })
        builder.addCase(requestAuth.fulfilled, (state, action) => {
            state.statusAuth = Status.SUCCESS;

            if (action.payload && action.payload.token) {
                localStorage.setItem('token', action.payload.token);
            }
        })
        builder.addCase(requestAuth.rejected, (state, action: any) => {
            state.statusAuth = Status.ERROR;
        })
    }
})

export const { setMessage, setStatus } = authSlice.actions;

export default authSlice.reducer;