import { createSlice } from '@reduxjs/toolkit';

import { requestGetBlog } from './asyncActions';
import { Status } from '../../types/types';
import { BlogSliceState } from './types';


const initialState: BlogSliceState = {
    blog: null,
    message: '',
    status: Status.CALM as Status,
}

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setBlogIsNull: (state) => {
            state.blog = null;
            state.status = Status.CALM;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestGetBlog.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetBlog.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;

            if (action.payload) {
                state.blog = action.payload;
            }
        })
        builder.addCase(requestGetBlog.rejected, (state, action: any) => {
            state.status = Status.ERROR;
        })
    }
})

export const { setBlogIsNull } = blogSlice.actions;

export default blogSlice.reducer;