import style from './ServicePay.module.scss';

import { useEffect, useRef, useState } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';
import { Checkbox, CheckboxProps } from 'antd';

// Telegram
import { MainButton, BackButton, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Router
import { useParams, useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { PromoCodeFormData } from '../../redux/promocode/types';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Component
import Spinner from '../../components/utils/spinner/Spinner';

// Types
import { Status } from '../../types/types';
import { selectPackages } from '../../redux/packages/selectors';
import { selectPayments } from '../../redux/payments/selectors';
import { CheckPaymentFormData, CreateUrlPaymentFormData } from '../../redux/payments/types';
import { requestCheckPayment, requestCreatePaymentUrl } from '../../redux/payments/asyncActions';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { setPaymentStatusIsNull, setPaymentUrlIsNull } from '../../redux/payments/slice';
import { requestMyServices } from '../../redux/my-services/asyncActions';
import { QuestionCircleOutlined } from '@ant-design/icons';


const checkEmailRegex = (email: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
        return true;
    }
    else {
        return false;
    }
}


const ServicePay = () => {
    const [email, setEmail] = useState<string>('');
    const intervalIdsRef = useRef<NodeJS.Timeout[]>([]);

    // Navigate
    const navigate = useNavigate();
    const { activePackage } = useSelector(selectPackages);

    // Get Path
    const { alias, verbose_name, price } = useParams();

    // Redux
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (alias) {
            dispatch(setPackageByAlias({alias: alias}))
        }
    }, [dispatch, alias])

    // Telegram
    const WebApp = useWebApp();

    // Promocode
    const [promocode, setPromocode] = useState<string>('');

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate Promocode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Payment Handler
    const { payment_url, statusPayment } = useSelector(selectPayments);
    const user: WebAppUser = WebApp.initDataUnsafe.user;

    const payment_handler = () => {
        if (activePackage) {
            if (email.length <= 0) {
                messageApi.error('Пожалуйста введите Email', 2);
                return;
            }
            if (!checkEmailRegex(email)) {
                messageApi.error('Пожалуйста введите Валидный Email', 2);
                return;
            }
            
            const formData: CreateUrlPaymentFormData = {
                alias: activePackage.alias,
                price: promo_package ? promo_package.price : activePackage.price,
                telegram_id: user.id,
                description: activePackage.description,
                verbose_name: activePackage.verbose_name,
                email: email,
            }
            dispatch(requestCreatePaymentUrl(formData));
        }
    }

    const [isOpenInvoice, setOpenInvoice] = useState<boolean>(false);

    useEffect(() => {
        if (payment_url) {
            WebApp.openLink(payment_url);
        }

        return () => {
            dispatch(setPromocodeIsNull());
            dispatch(setPaymentUrlIsNull());
            dispatch(setPaymentStatusIsNull());
            dispatch(requestMyServices());
        }
    }, [dispatch, payment_url, WebApp])

    // useEffect(() => {
    //     if (payment_url) {
    //         // clearAllIntervals();
    //         WebApp.openInvoice(payment_url);
    //         setOpenInvoice(true);

    //         WebApp.onEvent('invoiceClosed', (object: any) => {
    //             if (object.status === 'cancelled') {
    //                 messageApi.info('Вы отменили оплату', 2);
    //                 // clearAllIntervals();
    //                 dispatch(setPaymentUrlIsNull());
    //                 dispatch(setPaymentStatusIsNull());
    //             }
    //         });
    //     }
    //     return () => {
    //         clearAllIntervals();

    //         // dispatch(setPromocodeIsNull());
    //         dispatch(setPaymentUrlIsNull());
    //         dispatch(setPaymentStatusIsNull());
    //         dispatch(requestMyServices());
    //     }
    // }, [payment_url, WebApp, dispatch])

    // useEffect(() => {
    //     if (isOpenInvoice) {
    //         // Check Payment
    //         const formData: CheckPaymentFormData = {
    //             telegram_id: user.id,
    //         }

    //         if (intervalIdsRef.current.length === 0) {
    //             const intervalId = setInterval(() => {
    //                 dispatch(requestCheckPayment(formData));
    //             }, 5000);

    //             // Сохраняем идентификатор интервала в массиве
    //             intervalIdsRef.current.push(intervalId);
    //         }
    //     }
    // }, [isOpenInvoice, dispatch])

    // useEffect(() => {
    //     if (statusPayment) {
    //         clearAllIntervals();
    //         messageApi.success('Вы успешно оплатили услугу', 2);
    //     }
    // }, [statusPayment])


    // const clearAllIntervals = () => {
    //     intervalIdsRef.current.forEach(intervalId => clearInterval(intervalId));
    //     intervalIdsRef.current = [];  // Очищаем массив после удаления всех интервалов
    // };

    // Button
    const [isDisabled, setIsDisabled] = useState(true);

    const onChange: CheckboxProps['onChange'] = (e) => {
        setIsDisabled(!e.target.checked);
    };

    const handleOpenLink = (link: string) => {
        WebApp.openLink(link);
    }

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <div className={style.service_pay}>
            {contextHolder}
            <BackButton onClick={handleBack} />
            {alias && verbose_name && price
                ? (
                    <>
                        <h1 className={style.service_pay__title}>Оплата</h1>
                        <div className={style.service_pay__service_info}>
                            <h2>Оплата услуги: {verbose_name}</h2>
                        </div>
                        <div className={style.service_pay__promocode}>
                            <form>
                                <input
                                    type="text"
                                    value={promocode}
                                    placeholder='Есть промокод?'
                                    onChange={(e) => setPromocode(e.target.value)} />
                                <button onClick={handlerActivatePromocode}>Применить</button>
                            </form>
                            <div className={style.email_title}>
                                <p>Ваш Email</p>
                                <QuestionCircleOutlined
                                    onClick={() => messageApi.info('Ваш Email нужен, для того, чтобы отправить чек вам на почту', 2)}
                                />
                            </div>
                            <form>
                                <div className={style.service_pay__email_block}>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </form>
                        </div>
                        <div className={style.service_pay__personal_info}>
                            <Checkbox onChange={onChange} />
                            <p>Даю согласие на обработку персональных данных, соглашаюсь с <span onClick={() => handleOpenLink('https://my.astars.online/media/content/company/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-ASTARS_RU1.pdf')}>Политикой конфиденциальности</span> и принимаю условия <span onClick={() => handleOpenLink('https://my.astars.online/media/content/company/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%9F%D0%A0%D0%95%D0%94%D0%9E%D0%A1%D0%A2%D0%90%D0%92%D0%9B%D0%95%D0%9D%D0%98%D0%AF_%D0%A3%D0%A1%D0%9B%D0%A3%D0%93_ASTARS_05_05_6_ajHq8t5.pdf')}>Пользовательского соглашения (Оферты)</span></p>
                        </div>
                        <MainButton
                            color='#9696df'
                            disabled={isDisabled}
                            onClick={payment_handler}
                            text={`Купить ${promo_package ? promo_package.price : activePackage?.price} ₽`}
                        />
                    </>
                )
                : <Spinner />}
        </div>
    )
}

export default ServicePay;