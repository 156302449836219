import './assets/css/App.scss';

// Router
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Redux
import { ReduxProvider } from './redux/provider';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Pages
import Home from './pages/home/Home';
import Chats from './pages/chats/Chats';
import Welcome from './pages/welcome/Welcome';
import BlogComponent from './pages/blog/Blog';
import Package from './pages/package/Package';
import Profile from './pages/profile/Profile';
import Support from './pages/support/Support';
import Services from './pages/services/Services';
import OurAnalysis from './pages/our-analysis/OurAnalysis';
import StarsDirectory from './pages/stars-directory/StarsDirectory';
import StarDirectoryInfo from './pages/star-directory-info/StarDirectoryInfo';

// Layouts
import { ChatLayout, LogoLayout, MainLayout } from './layouts/Layouts';
import ServicePay from './pages/service-pay/ServicePay';
import Chat from './pages/chat/Chat';


function App() {
  // Setup TG Color
  const WebApp = useWebApp();

  WebApp.headerColor = '#9696df';

  // Full Screen TG
  if (!WebApp.isExpanded) {
    WebApp.expand();
  }

  // Enable Closing Confirm
  WebApp.enableClosingConfirmation();

  return (
    <ReduxProvider>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Welcome />} />

            <Route path='/home' element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path='/home/stars-directory' element={<StarsDirectory />} />
              <Route path='/home/stars-directory/:name' element={<StarDirectoryInfo />} />
              <Route path='/home/chats' element={<Chats />} />
              <Route path='/home/services' element={<Services />} />
              <Route path='/home/profile' element={<Profile />} />
            </Route>

            <Route path='/home/blog/:blogId' element={<BlogComponent />} />
            
            <Route path='/home' element={<LogoLayout />}>
              <Route path='/home/package/:alias' element={<Package />} />
              <Route path='/home/support' element={<Support />} />
              <Route path='/home/our-analysis' element={<OurAnalysis />} />
              <Route path='/home/service-pay/:alias/:verbose_name/:price' element={<ServicePay />} />
            </Route>

            <Route path='/home' element={<ChatLayout />}>
              <Route path='chat/:chatId' element={<Chat />} />
            </Route>
          </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
