import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { requestMyServices, requestActiveService } from './asyncActions';

import { ServicesSliceState, Status } from './types';



const initialState: ServicesSliceState = {
    message: '',
    status: Status.CALM as Status,
    my_services: [],
    activeService: null,
    request_id: null,
}

const myServicesSlice = createSlice({
    name: 'my_services',
    initialState,
    reducers: {
        setServiceByAlias: (state, action: PayloadAction<number>): void => {
            const services = state.my_services;

            if (services && services.length > 0) {
                state.activeService = services[action.payload];
            }
        },
        setMessage(state, action: PayloadAction<string>) {
            state.message = action.payload;
        },
        setRequestId(state, action: PayloadAction<number | null>) {
            state.request_id = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Get My Services
        builder.addCase(requestMyServices.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestMyServices.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            if (action.payload) {
                state.my_services = action.payload;
            }
        })
        builder.addCase(requestMyServices.rejected, (state, action) => {
            state.status = Status.ERROR;
        })

        // Active Service
        builder.addCase(requestActiveService.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestActiveService.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            if (action.payload) {
                state.request_id = action.payload.request_id;
                state.message = 'Услуга успешно активирована';
            }
        })
        builder.addCase(requestActiveService.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при активации услуги';
        })
    }
})

export const { setServiceByAlias, setMessage, setRequestId } = myServicesSlice.actions;

export default myServicesSlice.reducer;