import { createAsyncThunk } from '@reduxjs/toolkit';

import ServicesService from '../../services/ServicesService';
import { ActiveServiceFormData } from './types';


export const requestMyServices = createAsyncThunk('/client/v1/user/package/active', async () => {
    const response = await ServicesService.getMyServices();
    return response;
})

export const requestActiveService = createAsyncThunk('/telegram-web-app/v1/user/request', async (formData: ActiveServiceFormData) => {
    const response = await ServicesService.activeService(formData);
    return response;
})