import style from './Messages.module.scss';

// Date
import Moment from 'moment';

// Ant Design
import { Empty, Image, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Components
import { useEffect, useRef } from 'react';
import { ChatData } from '../../../redux/chats/types';

type Props = {
    activeChat: ChatData | null;
}


const Messages = ({ activeChat }: Props) => {
    // Telegram
    const WebApp = useWebApp();

    // Scroll Bottom
    const messagesEndRef = useRef<null | HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [activeChat && activeChat.messages.length]);

    const onDownload = (src: string) => {
        WebApp.openLink(src);
    };

    return (
        activeChat && activeChat.messages.length > 0
            ? (
                <>
                    {activeChat.messages.map((message, idx) => (
                        <div key={idx} className={style.messages}>
                            {message.type === 'text' && (
                                message.kind === 'customer'
                                    ? (
                                        
                                        <div className={style.chat__send_message} key={message.id}>
                                            <p dangerouslySetInnerHTML={{__html: message.value}}></p>
                                            <p>{Moment(message.dt_created).format('HH:mm')}</p>
                                        </div>
                                    )
                                    : !message.value.includes('add_feedback') && (
                                        <div className={style.chat__receiver_message} key={message.id}>
                                            <p dangerouslySetInnerHTML={{__html: message.value}}></p>
                                            <p>{Moment(message.dt_created).format('HH:mm')}</p>
                                        </div>
                                    )
                            )}
                            <div key={idx}>
                                {message.type === 'image' && (
                                    message.kind === 'customer'
                                        ? (
                                            <div className={style.chat__send_message_img}>
                                                <Image
                                                    width={'100%'}
                                                    src={message.link}
                                                    preview={{
                                                        toolbarRender: (
                                                            _,
                                                        ) => (
                                                            <Space size={12} className="toolbar-wrapper">
                                                                <DownloadOutlined style={{ fontSize: 40 }} onClick={() => onDownload(message.link)} />
                                                            </Space>
                                                        ),
                                                    }}
                                                />
                                                <p>{Moment(message.dt_created).format('HH:mm')}</p>
                                            </div>
                                        )
                                        : (
                                            <div className={style.chat__receiver_message_img}>
                                                <Image
                                                    width={'100%'}
                                                    src={message.link}
                                                    preview={{
                                                        toolbarRender: (
                                                            _,
                                                        ) => (
                                                            <Space size={12} className="toolbar-wrapper">
                                                                <DownloadOutlined style={{ fontSize: 40 }} onClick={() => onDownload(message.link)} />
                                                            </Space>
                                                        ),
                                                    }}
                                                />
                                                <p>{Moment(message.dt_created).format('HH:mm')}</p>
                                            </div>
                                        )
                                )}
                                {message.type === 'music' && (
                                    message.kind === 'customer'
                                        ? (
                                            <div className={style.chat__send_message_file}>
                                                <p>{message.value}</p>
                                                <button onClick={() => onDownload(message.link)}>Скачать Документ</button>
                                                <p>{Moment(message.dt_created).format('HH:mm')}</p>
                                            </div>
                                        )
                                        : (
                                            <div className={style.chat__receiver_message_file}>
                                                <p>{message.value}</p>
                                                <button onClick={() => onDownload(message.link)}>Скачать Документ</button>
                                                <p>{Moment(message.dt_created).format('HH:mm')}</p>
                                            </div> 
                                        )
                                )}
                                {message.type === 'file' && (
                                    message.kind === 'customer'
                                        ? (
                                            <div className={style.chat__send_message_file}>
                                                <p>{message.value}</p>
                                                <button onClick={() => onDownload(message.link)}>Скачать Документ</button>
                                                <p>{Moment(message.dt_created).format('HH:mm')}</p>
                                            </div>
                                        )
                                        : (
                                            <div className={style.chat__receiver_message_file}>
                                                <p>{message.value}</p>
                                                <button onClick={() => onDownload(message.link)}>Скачать Документ</button>
                                                <p>{Moment(message.dt_created).format('HH:mm')}</p>
                                            </div> 
                                        )
                                )}
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </>
            )
            : (
                <Empty
                    className={style.chat__empty}
                    description={
                        <span>
                            Нет сообщений
                        </span>
                    }
                />
            )
    )
}

export default Messages;