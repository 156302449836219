import style from './Chats.module.scss';

// Ant Design
import { RightSquareFilled } from '@ant-design/icons';

// Router
import { Link, useNavigate } from 'react-router-dom';

// React
import { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectMyChats } from '../../redux/chats/selectors';
import { requestGetChats } from '../../redux/chats/asyncActions';

// Components
import ButtonMain from '../../components/buttons/Buttons';

// Types
import { Status } from '../../types/types';


const Chats = () => {
    // Navigate
    const navigate = useNavigate();

    // Redux
    const dispatch = useAppDispatch();

    // Active / No Active
    const [active, setActive] = useState<number>(0);

    // Get Chats
    const { statusChat, chats, closedChats } = useSelector(selectMyChats);

    // Get My Chats
    useEffect(() => {
        if (!chats.length && statusChat === Status.CALM) {
            dispatch(requestGetChats());
        }
    }, [chats, statusChat, dispatch])

    const handleClickToServices = () => {
        navigate('/home/services');
    }

    return (
        <div className={style.chats}>
            <h1 className={style.chats__title}>Чаты</h1>
            {chats.length > 0 || closedChats.length > 0
                ? (
                    <>
                        <div className={style.chats__toogle_menu}>
                            <button
                                onClick={() => setActive(0)}
                                className={active === 0 ? style.active : ''}>Активные</button>
                            <button
                                onClick={() => setActive(1)}
                                className={active === 1 ? style.active : ''}>Неактивные</button>
                        </div>
                        {active === 0
                            ? (
                                chats.length > 0
                                ? (
                                    <div className={style.active_chats}>
                                        {chats.map((chat, idx) => (
                                            <Link to={`/home/chat/${chat.request_id}`} key={idx} className={style.chats__chat}>
                                                <div className={style.chats__chat__up_block}>
                                                    <p>Консультация началась</p>
                                                    <RightSquareFilled />
                                                </div>
                                                <p>Консультация "{chat.package_name}"</p>
                                            </Link>
                                        ))}
                                    </div>
                                )
                                : <p className={style.chats__text}>На данный момент у вас нет активных чатов</p>
                            )
                            : (
                                closedChats.length > 0
                                    ? (
                                        <div className={style.active_chats}>
                                            {closedChats.map((chat, idx) => (
                                                <Link to={`/home/chat/${chat.request_id}`} key={idx} className={style.chats__chat}>
                                                    <div className={style.chats__chat__up_block}>
                                                        <p>Консультация завершилась</p>
                                                        <RightSquareFilled />
                                                    </div>
                                                    <p>Консультация "{chat.package_name}"</p>
                                                </Link>
                                            ))}
                                        </div>
                                    )
                                    : <p className={style.chats__text}>На данный момент у вас нет архивных чатов</p>
                            )}
                    </>
                )
                : (
                    <p className={style.chats__text}>У вас пока нет активных переписок, перейдите в раздел "Услуги" для покупки консультации</p>
                )}
            {chats.length === 0 && closedChats.length === 0 && (
                <div onClick={handleClickToServices} className={style.chats__btn}>
                    <ButtonMain text='Перейти' />
                </div>
            )}
        </div>
    )
}

export default Chats;