import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { requestPackages } from './asyncActions';

import { Status } from '../../types/types';
import { PackagesSliceState, getPackageByAlias } from './types';


const initialState: PackagesSliceState = {
    message: '',
    status: Status.CALM as Status,
    packages: [],
    activePackage: null,
}

const packagesSlice = createSlice({
    name: 'packages',
    initialState,
    reducers: {
        setPackageByAlias: (state, action: PayloadAction<getPackageByAlias>): void => {
            const alias = action.payload.alias;
            
            if (state.packages && state.packages.length > 0) {
                const idx = state.packages.findIndex((el) => el.alias === alias);
                if (idx !== -1) {
                    state.activePackage = state.packages[idx];
                }
            }
        },
        setActiveIsNull: (state): void => {
            state.activePackage = null;
        }
    },
    extraReducers: (builder) => {
        // Get Packages
        builder.addCase(requestPackages.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestPackages.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.packages = action.payload;
            }
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestPackages.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
    },
})

export const { setPackageByAlias, setActiveIsNull } = packagesSlice.actions;

export default packagesSlice.reducer;