import { createSlice } from '@reduxjs/toolkit';

import { PromoCodeSliceState, Status } from './types';
import { requestActivatePromoCode } from './asyncActions';

const initialState: PromoCodeSliceState = {
    message: '',
    status: Status.CALM as Status,
    promo_package: null,
}

const promoCodeSlice = createSlice({
    name: 'promocode',
    initialState,
    reducers: {
        setPromocodeIsNull: (state) => {
            state.promo_package = null;
            state.status = Status.CALM;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestActivatePromoCode.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestActivatePromoCode.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.promo_package = action.payload;
                state.message = 'Промокод активирован';
            } else {
                state.status = Status.ERROR;
                state.message = 'Неверный или неактивный промокод';
            }
        })
        builder.addCase(requestActivatePromoCode.rejected, (state) => {
            state.status = Status.ERROR;
        })
    }
})

export const { setPromocodeIsNull } = promoCodeSlice.actions;

export default promoCodeSlice.reducer;