import axios from 'axios';

import { API_URL } from '../http';

export default class PackagesService {
    static async getPackages() {
        const res = await axios.get(`${API_URL}client/v1/package`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    // static async getMyActivePackages() {
    //     const res = await $api.get('client/v1/user/package/active')
    //     .then((res) => {
    //         return res.data;
    //     })
    //     .catch((err) => {
    //         return err.response.data;
    //     })
    //     return res;
    // }
    // static async getMyInactivePackages() {
    //     const res = await $api.get('client/v1/user/package/inactive')
    //    .then((res) => {
    //         return res.data;
    //    })
    //    .catch((err) => {
    //     return err.response.data;
    //    })
    //    return res;
    // }
    // static async activeService(formData: ActiveServiceFormData) {
    //     const res = await $api.post('telegram-web-app/v1/user/request', formData)
    //     .then((res) => {
    //         return res.data;
    //     })
    //     .catch((err) => {
    //         return err.response.data;
    //     })
    //     return res;
    // }
}