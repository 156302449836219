export function getFormatFile(filename: string): string {
    const fileExt = filename.split('.').pop();
    if (fileExt === 'png' || fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'gif') {
        return 'image';
    }
    if (fileExt === 'pdf' ||
        fileExt === 'doc' ||
        fileExt === 'docx' ||
        fileExt === 'xls' ||
        fileExt === 'xlsx' ||
        fileExt === 'txt' ||
        fileExt === 'ppt' ||
        fileExt === 'pptx' ||
        fileExt === 'epub' ||
        fileExt === 'odt') {
        return 'file';
    }
    return 'voice';
}
