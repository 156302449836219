import style from './Packages.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { Link, useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { selectPackages } from '../../../redux/packages/selectors';
import { useAppDispatch } from '../../../redux/store';
import { requestPackages } from '../../../redux/packages/asyncActions';

// Ant Design
import { DownOutlined, UpOutlined } from '@ant-design/icons';

// Types
import { Status } from '../../../types/types';

// Components
import Spinner from '../../utils/spinner/Spinner';
import { Package } from '../../../redux/packages/types';
import { setPackageByAlias } from '../../../redux/packages/slice';


type Props = {
    title: string;
}

const Packages = ({ title }: Props) => {
    // Navigate
    const navigate = useNavigate();

    // Get Packages
    const { status, packages } = useSelector(selectPackages);

    // Redux
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (packages.length === 0 && status === Status.CALM) {
            dispatch(requestPackages());
        }
    }, [dispatch, packages, status])

    // Filter Packages
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [filterPackages, setFilterPackages] = useState<[] | Package[]>([]);
    const [filter, setFilter] = useState<string>('Все категории');

    useEffect(() => {
        if (packages.length > 0) {
            setFilterPackages(packages);
        }
    }, [packages])

    const filterPackagesByTitle = (title: string) => {
        if (title === 'Все категории') {
            setFilterPackages(packages);
        } else {
            const newPackages = packages.filter(p => p.category.includes(title));
            setFilterPackages(newPackages);
        }
        setOpenFilter(false);
        setFilter(title);

        // Scroll to Left
        const packagesElement = document.querySelector('.' + style.packages);
        if (packagesElement) {
            packagesElement.scrollLeft = 0;
        }
    }

    const handleActivePackage = (e: any, alias: string) => {
        e.preventDefault();

        // Set Active Package Alias
        dispatch(setPackageByAlias({alias: alias}));

        // Navigate to Package Page Information
        navigate(`/home/package/${alias}`);
    }

    const onClickBuy = (e: any, alias: string, verbose_name: string, price: number) => {
        e.preventDefault();
        navigate(`/home/service-pay/${alias}/${verbose_name}/${price}`);
    }

    return (
        <>
            {packages.length === 0 || status === Status.LOADING
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.consultations}>
                            <div className={style.consultations__title}>
                                <p>{title}</p>
                            </div>
                        </div>
                        <div className={style.packages_filter}>
                            <div className={style.packages_filter__button} onClick={() => setOpenFilter(!openFilter)}>
                                <p>{filter}</p>
                                {openFilter ? <UpOutlined /> : <DownOutlined />}
                            </div>
                            {openFilter && (
                                <div className={style.packages_filter__items}>
                                    <ul>
                                        <li onClick={() => filterPackagesByTitle('Все категории')}>Все категории</li>
                                        <li onClick={() => filterPackagesByTitle('Бизнес')}>Бизнес</li>
                                        <li onClick={() => filterPackagesByTitle('Деньги')}>Деньги</li>
                                        <li onClick={() => filterPackagesByTitle('Карма')}>Карма</li>
                                        <li onClick={() => filterPackagesByTitle('Личность')}>Личность</li>
                                        <li onClick={() => filterPackagesByTitle('Любовь и отношения')}>Любовь и отношения</li>
                                        <li onClick={() => filterPackagesByTitle('Натальная карта')}>Натальная карта</li>
                                        <li onClick={() => filterPackagesByTitle('О детях')}>О детях</li>
                                        <li onClick={() => filterPackagesByTitle('Прогнозы')}>Прогнозы</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className={style.packages} style={filterPackages.length === 1 ? {justifyContent: 'center'} : {}}>
                            {filterPackages.map((pkg, idx) => (
                                <div className={style.packages__package} key={idx}>
                                    <h1>{pkg.verbose_name}</h1>
                                    <img src={pkg.image_thumbnail} alt={`img-pkg-${idx}`} />
                                    <p className={style.package__description} dangerouslySetInnerHTML={{__html: pkg.description.replace('<strong>Вы узнаете:&nbsp;</strong>', '').replace('<strong>Вы получите:&nbsp;</strong>', '').replace('<strong>Вы получите:</strong>', '').replace('<strong>Вы узнаете:</strong>&nbsp;', '')}}></p>
                                    <div className={style.package_price}>
                                        <p>Цена:</p>
                                        <p>{pkg.price} ₽</p>
                                    </div>
                                    <button onClick={(e) => onClickBuy(e, pkg.alias, pkg.verbose_name, pkg.price)}>Купить</button>
                                    <Link onClick={(e) => handleActivePackage(e, pkg.alias)} to={''}>Подробнее</Link>
                                </div>
                            ))}
                        </div>
                    </>
                )}
        </>
    )
}

export default Packages;