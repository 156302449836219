import style from './Support.module.scss';

// Ant Design
import { DownOutlined, MailOutlined, PhoneOutlined, UpOutlined } from '@ant-design/icons';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// React
import { useState } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Logo
import vkLogo from '../../assets/img/support/vk.svg';
import telegramLogo from '../../assets/img/support/telegram.svg';


interface MenuSupportItem {
    id: number;
    title: string;
    description: string;
}

const menuSupportItems: MenuSupportItem[] = [
    {
        id: 0,
        title: 'Что такое натальная карта?',
        description: 'Натальная карта — это своего рода космический паспорт, который показывает расположение планет во время нашего рождения. Она содержит очень много информации и остается неизменной на протяжении всей жизни человека. Проще говоря, это схема энергий, которая показывает вашу индивидуальность, все ключевые точки в его судьбе и вероятные сценарии. Для того, чтобы вы реализовали наилучший.',
    },
    {
        id: 1,
        title: 'Как воспользоваться нашим сервисом?',
        description: 'Выберите консультацию, которая Вас интересует, далее оплатите и активируете свой Личный кабинет. При активации Личного кабинета введите свои время, дату и место рождения, это нужно для составления натальной карты. Далее наши астрологи проведут анализ Вашей карты и дадут ответ в чате в PDF формате. Если у Вас останутся вопросы Вы всегда можете обратиться к нам через коллцентр (8 800 600 99 93) или написать интересующий Ваш вопрос, выбрав на нашем сайте любой, доступный Вам мессенджер (Telegram, Viber, WhatsApp или чат на сайте).С Вами свяжется наш Менеджер. Он может решить любой спорный или непонятный вопрос касаемо взаимодействия, пакетного обслуживания или технических аспектов.',
    },
    {
        id: 2,
        title: 'Кто вы, Astars?',
        description: 'Мы астрологи с успешным опытом, которому более 10-ти лет! Для нас важно помочь вам и сделать вашу жизнь лучше!',
    },
    {
        id: 3,
        title: 'Могу ли я быть уверенным в конфиденциальности своих данных?',
        description: 'Да, конечно! Наш приоритет — это ваша безопасность и конфиденциальность внутри нашего сервиса!',
    },
    {
        id: 4,
        title: 'Зачем астрологу так много моих данных?',
        description: 'При заказе натальной карты нашим астрологам нужно знать 3 аспекта: место, время и дата рождения. При условии, что клиент не знает время рождения — мы предлагаем услугу «ректификация» определение времени рождения по ключевым событиям в жизни. Для этого нужны чёткие даты событий (например: свадьба 12.04.2020, рождение ребёнка 4.08.2022 и тд.). Если такой информацией клиент не хочет распространяться — наши астрологи могут предложить составить космограмму, это тоже натальная карта, но без 40% информации.',
    }
]


const Support = () => {
    const [natalCard, setNatalCard] = useState<boolean>(false);
    const [useService, setUseService] = useState<boolean>(false);
    const [whoIs, setWhoIs] = useState<boolean>(false);
    const [confInfo, setConfInfo] = useState<boolean>(false);
    const [myData, setMyData] = useState<boolean>(false);

    // Navigate
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/home/profile');
    }

    return (
        <div className={style.support}>
            <BackButton onClick={handleBack} />
            <h1 className={style.support__title}>Поддержка</h1>
            <div className={style.support__menu_items}>
                {menuSupportItems.map(support => (
                    <>
                        <div
                            className={style.support__menu_item}
                            onClick={() => {
                                if (support.id === 0) {
                                    setNatalCard(!natalCard);
                                } else if (support.id === 1) {
                                    setUseService(!useService);
                                } else if (support.id === 2) {
                                    setWhoIs(!whoIs);
                                } else if (support.id === 3) {
                                    setConfInfo(!confInfo);
                                } else {
                                    setMyData(!myData);
                                }
                            }}
                        >
                            <div className={style.support__menu__title}>
                                <p>{support.title}</p>
                                {support.id === 0 && natalCard && <UpOutlined />}
                                {support.id === 0 && !natalCard && <DownOutlined />}

                                {support.id === 1 && useService && <UpOutlined />}
                                {support.id === 1 && !useService && <DownOutlined />}

                                {support.id === 2 && whoIs && <UpOutlined />}
                                {support.id === 2 && !whoIs && <DownOutlined />}

                                {support.id === 3 && confInfo && <UpOutlined />}
                                {support.id === 3 && !confInfo && <DownOutlined />}

                                {support.id === 4 && myData && <UpOutlined />}
                                {support.id === 4 && !myData && <DownOutlined />}
                            </div>

                            {support.id === 0 && natalCard && (
                                <p className={style.support__menu__description}>{support.description}</p>
                            )}
                            {support.id === 1 && useService && (
                                <p className={style.support__menu__description}>{support.description}</p>
                            )}
                            {support.id === 2 && whoIs && (
                                <p className={style.support__menu__description}>{support.description}</p>
                            )}
                            {support.id === 3 && confInfo && (
                                <p className={style.support__menu__description}>{support.description}</p>
                            )}
                            {support.id === 4 && myData && (
                                <p className={style.support__menu__description}>{support.description}</p>
                            )}
                        </div>
                    </>
                ))}
            </div>
            <div className={style.support__questions}>
                <h2>Если остались ещё вопросы, свяжитесь с нами</h2>
                <div className={style.support__questions__contacts}>
                    <div className={style.support__questions__contacts__contact}>
                        <PhoneOutlined />
                        <p>8 800 600 99 93</p>
                    </div>
                    <div className={style.support__questions__contacts__contact}>
                        <MailOutlined />
                        <p>info@astars.online</p>
                    </div>
                </div>
            </div>
            <div className={style.support__social_networks}>
                <a href='https://vk.com/astarsonline' className={style.support__social_networks__network}>
                    <img src={vkLogo} alt="vkontakte-logo" />
                </a>
                <a href='https://t.me/astarsonline' className={style.support__social_networks__network}>
                    <img src={telegramLogo} alt="telegram-logo" />
                </a>
            </div>
        </div>
    )
}

export default Support;