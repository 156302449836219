import style from './Blogs.module.scss';

// Router
import { Link } from 'react-router-dom';

// React
import { useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { selectBlogs } from '../../../redux/blogs/selectors';
import { requestGetBlogs } from '../../../redux/blogs/asyncActions';

// Types
import { Status } from '../../../types/types';

// Components
import Spinner from '../../utils/spinner/Spinner';


const Blogs = () => {
    // Redux
    const dispatch = useAppDispatch();

    // Get Blogs
    const { status, blogs } = useSelector(selectBlogs);

    useEffect(() => {
        if (status === Status.CALM && blogs.length === 0) {
            dispatch(requestGetBlogs());
        }
    }, [dispatch, blogs, status])
    return (
        <div className={style.home_page__blogs}>
            {status === Status.LOADING || blogs.length === 0
            ? <Spinner />
            : (
                blogs.length > 0 && blogs.map(blog =>(
                    <Link to={`/home/blog/${blog.id}`} key={blog.id}>
                        <img src={blog.image} alt={`blog-img-${blog.id}`} />
                        <p>{blog.title}</p>
                    </Link>
                ))
            )}
        </div>
    )
}

export default Blogs;