export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface ActiveServiceFormData {
    service_id: number;
    comment: string;
    file_ids?: [];
}

export interface Services {
    id: number;
    verbose_name: string;
    quantity: number;
    initial_quantity: number;
    config: [];
    description: string;
    is_video_conference: boolean;
}

export interface MyService {
    image_thumbnail: string;
    dark_theme_image_thumbnail: any;
    alias: string;
    verbose_name: string;
    services: Services[] | [];
    dt_start: string;
    dt_end: string;
    prolongation_price: number;
    prolongation_state: boolean;
    css_class: string;
    description: string;
    detailed_description: string | null;
    mobile_description: string | null;
}

export interface ServicesSliceState {
    status: Status | string;
    message: string;
    my_services: MyService[] | [];
    activeService: MyService | null;
    request_id: number | null;
}