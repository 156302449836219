import axios from 'axios';

import { API_URL } from '../http';


export default class BlogService {
    static async getBlogs() {
        const res = await axios.get(`${API_URL}blog/v1/articles`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        });
        return res;
    }
    static async getBlog(blogId: string) {
        const res = await axios.get(`${API_URL}blog/v1/article/${blogId}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        });
        return res;
    }
}