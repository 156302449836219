import style from './ModalActivateService.module.scss';

// React
import { useEffect, useState } from 'react';

// Navigate
import { useNavigate } from 'react-router-dom';

// Ant Design
import { Input, Modal } from 'antd';
import { message as antdMessage } from 'antd';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectMyServices } from '../../redux/my-services/selectors';
import { requestActiveService } from '../../redux/my-services/asyncActions';
import { setMessage, setRequestId } from '../../redux/my-services/slice';


type Props = {
    isShow: boolean;
    serviceId: number | null;
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalActivateService = ({ isShow, setIsShow, serviceId }: Props) => {
    // Show Alert
    const navigate = useNavigate();
    const [question, setQuestion] = useState<string>('');
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Redux
    const dispatch = useAppDispatch();
    const { message, request_id } = useSelector(selectMyServices);

    const activeService = () => {
        if (serviceId) {
            dispatch(requestActiveService({
                comment: question,
                service_id: serviceId,
            }))
        } else {
            messageApi.error('Произошла ошибка при активации услуги. Попробуйте перезагрузить приложение', 4);
            return;
        }
    }

    const handleOk = () => {
        if (question.length === 0) {
            messageApi.info('Введите вопрос', 2);
            return;
        }
        setIsShow(false);
        setQuestion('');
        activeService();
    };

    const handleCancel = () => {
        setIsShow(false);
        setQuestion('');
    };

    useEffect(() => {
        if (message.length > 0) {
            if (request_id) {
                navigate(`/home/chat/${request_id}`);
            } else {
                messageApi.error(message, 2);
            }
        }

        return () => {
            dispatch(setMessage(''));
            dispatch(setRequestId(null));
        }
    }, [message, request_id, dispatch, messageApi, navigate])

    return (
        <div className={style.modal_activate}>
            {contextHolder}
            <Modal
                title="Ваш вопрос"
                okText="ОК"
                cancelText="Отмена"
                open={isShow}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <form>
                    <Input value={question} onChange={(e) => setQuestion(e.target.value)} />
                </form>
            </Modal>
        </div>
    )
}

export default ModalActivateService;