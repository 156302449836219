import style from './Logo.module.scss';

import logoCompany from '../../../assets/img/home/logo.svg';


const Logo = () => {
    return (
        <div className={style.logo}>
            <img src={logoCompany} alt="logo" />
        </div>
    )
}

export default Logo;