import style from './OurAnalysis.module.scss';

// Router
import { useNavigate } from 'react-router-dom';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';


const OurAnalysis = () => {
    // Navigate
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/home/profile');
    }

    return (
        <div className={style.our_analysis}>
            <BackButton onClick={handleBack} />
            <h1 className={style.our_analysis__title}>Ваши разборы</h1>
        </div>
    )
}

export default OurAnalysis;