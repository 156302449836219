import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../types/types';
import { BlogSliceState } from './types';
import { requestGetBlogs } from './asyncActions';


const initialState: BlogSliceState = {
    blogs: [],
    message: '',
    status: Status.CALM as Status,
}

const blogsSlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(requestGetBlogs.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetBlogs.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;

            if (action.payload) {
                state.blogs = action.payload;
            }
        })
        builder.addCase(requestGetBlogs.rejected, (state, action: any) => {
            state.status = Status.ERROR;
        })
    }
})

export const {} = blogsSlice.actions;

export default blogsSlice.reducer;