import { Package } from "../packages/types";

export interface PromoCodeFormData {
    promocode: string;
    service: string;
}

export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface PromoCodeSliceState {
    status: Status | string;
    message: string;
    promo_package: Package | null; 
}