import $api from '../http/index';
import { CreateMessageFormData, UploadFileFormData } from '../redux/chats/types';


export default class ChatsService {
    static async getChats() {
        const res = await $api.get('client/v1/user/request?limit=100&offset=0')
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getMessages(chatID: string | undefined) {
        const res = await $api.get(`client/v1/user/request/${chatID}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async createMessage(formData: CreateMessageFormData) {
        const res = await $api.post('client/v1/message', formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async uploadFile(formData: UploadFileFormData) {
        const res = await $api.post('client/v1/file', formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}