import $api from '../http/index';
import { ActiveServiceFormData } from '../redux/my-services/types';


export default class ServicesService {
    static async getServicesCompany() {}
    static async getMyServices() {
        const res = await $api.get('client/v1/user/package/active')
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async activeService(formData: ActiveServiceFormData) {
        const res = await $api.post('telegram-web-app/v1/user/request', formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}