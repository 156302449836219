import style from './Services.module.scss';

import { useEffect, useState } from 'react';

// Moment
import moment from 'moment';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectMyServices } from '../../redux/my-services/selectors';
import { requestMyServices } from '../../redux/my-services/asyncActions';

// Types
import { Status } from '../../types/types';

// Components
import Spinner from '../../components/utils/spinner/Spinner';
import Packages from '../../components/home/packages/Packages';
import ModalActivateService from '../../components/modals/ModalActivateService';


const Services = () => {
    // Redux
    const dispatch = useAppDispatch();

    const { status, my_services } = useSelector(selectMyServices);

    useEffect(() => {
        if (!my_services.length && status === Status.CALM) {
            dispatch(requestMyServices());
        }
    }, [my_services, dispatch, status])

    const getCountDays = (dtStart: string, dtEnd: string) => {
        const start = moment(dtStart);
        const end = moment(dtEnd);

        const duration = moment.duration(end.diff(start));
        const days = duration.asDays();
        return days;
    }

    // Activate Service
    const [isShow, setIsShow] = useState<boolean>(false);
    const [activeServiceId, setActiveServiceId] = useState<number | null>(null);

    const activateService = (serviceId: number) => {
        setActiveServiceId(serviceId);
        setIsShow(true);
    }

    return (
        <div className={style.services}>
            <ModalActivateService isShow={isShow} setIsShow={setIsShow} serviceId={activeServiceId} />
            <h1 className={style.services__title}>Услуги</h1>
            <div className={style.services__active_service}>
                <h2>Активные услуги</h2>
                {status === Status.LOADING
                    ? (
                        <Spinner />
                    )
                    : (
                        my_services.length > 0
                            ? (
                                <div
                                    className={style.services}
                                    style={my_services.length === 1
                                            ? {justifyContent: 'center', display: 'flex'}
                                            : {display: 'flex', gap: '10px', maxWidth: '100%', marginTop: '25px', overflowY: 'hidden'}}
                                >
                                    {my_services.map((service, idx) => (
                                        <div className={style.service} key={idx}>
                                            <h1>{service.verbose_name}</h1>
                                            <img src={service.image_thumbnail} alt={`img-pkg-${idx}`} />
                                            <p className={style.service__description} dangerouslySetInnerHTML={{__html: service.description.replace('<strong>Вы узнаете:&nbsp;</strong>', '').replace('<strong>Вы получите:&nbsp;</strong>', '').replace('<strong>Вы получите:</strong>', '').replace('<strong>Вы узнаете:</strong>&nbsp;', '')}}></p>
                                            <div className={style.service_date}>
                                                <p>Срок действия:</p>
                                                <p>{getCountDays(service.dt_start, service.dt_end)} дней</p>
                                            </div>
                                            <button onClick={() => activateService(service.services[0].id)}>Перейти в чат</button>
                                        </div>
                                    ))}
                                </div>
                            )
                            : <p>У вас пока нет активных услуг</p>
                    )}
            </div>
            <div className={style.services__catalog}>
                <Packages title='Каталог услуг' />
            </div>
        </div>
    )
}

export default Services;