import style from './StarsDirectory.module.scss';

// Router
import { Link } from 'react-router-dom';

interface StarsDirectoryLink {
    src: string;
    name: string;
}

const starsDirectoryType: StarsDirectoryLink[] = [
    {
        src: '/home/stars-directory/aspects',
        name: 'Аспекты',
    },
    {
        src: '/home/stars-directory/astrological_transit',
        name: 'Астрологический транзит',
    },
    {
        src: '/home/stars-directory/ascendant',
        name: 'Асцендент (восходящий знак)',
    },
    {
        src: '/home/stars-directory/houses',
        name: 'Дома',
    },
    {
        src: '/home/stars-directory/zodiac_sign',
        name: 'Знак зодиака',
    },
    {
        src: '/home/stars-directory/meridian',
        name: 'Меридиан (MC, Medium Coeli)',
    },
    {
        src: '/home/stars-directory/natal_chart',
        name: 'Натальная карта',
    },
    {
        src: '/home/stars-directory/planet',
        name: 'Планета',
    },
    {
        src: '/home/stars-directory/progressions',
        name: 'Прогрессии',
    },
    {
        src: '/home/stars-directory/retrograde',
        name: 'Ретроградность',
    },
    {
        src: '/home/stars-directory/synastry',
        name: 'Синастрия',
    }
]


const StarsDirectory = () => {
    return (
        <div className={style.stars_directory}>
            <h1 className={style.stars_directory__title}>Звездный справочник</h1>
            <div className={style.stars_directory__links}>
                {starsDirectoryType.map((star) => (
                    <Link key={star.name} to={star.src}>
                        <p>{star.name}</p>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default StarsDirectory;