import style from './Layouts.module.scss';

// Router
import { Outlet } from 'react-router-dom';

// Components
import Logo from '../components/utils/logo/Logo';
import Navbar from '../components/utils/navbar/Navbar';

export function MainLayout() {
    return (
        <div className={style.main_layout}>
            <Logo />
            <div className={style.main_layout__content}>
                <Outlet />
            </div>
            <Navbar />
        </div>
    )
}

export function LogoLayout() {
    return (
        <div className={style.logo_layout}>
            <Logo />
            <div className={style.logo_layout__content}>
                <Outlet />
            </div>
        </div>
    )
}

export function ChatLayout() {
    return (
      <>
        <Outlet />
      </>
    )
  }