import style from './Navbar.module.scss';

// Router
import { Link, useLocation } from 'react-router-dom';

// Icons
import { BookOutlined, HomeOutlined, MessageOutlined, ShoppingOutlined, UserOutlined } from '@ant-design/icons';


const Navbar = () => {
    const { pathname } = useLocation();

    return (
        <div className={style.navbar}>
            <ul>
                <li className={pathname === '/home' ? style.active : ''}
                >
                    <Link to={'/home'}>
                        <HomeOutlined />
                    </Link>
                </li>
                <li className={pathname.includes('/home/stars-directory') ? style.active : ''}>
                    <Link to={'/home/stars-directory'}>
                        <BookOutlined />
                    </Link>
                </li>
                <li className={pathname === '/home/chats' ? style.active : ''}>
                    <Link to={'/home/chats'}>
                        <MessageOutlined />
                    </Link>
                </li>
                <li className={pathname === '/home/services' ? style.active : ''}>
                    <Link to={'/home/services'}>
                        <ShoppingOutlined />
                    </Link>
                </li>
                <li className={pathname === '/home/profile' ? style.active : ''}>
                    <Link to={'/home/profile'}>
                        <UserOutlined />
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Navbar;