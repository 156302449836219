import style from './StarDirectoryInfo.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Components
import Spinner from '../../components/utils/spinner/Spinner';


interface StarDirectoryInfo {
    path: string;
    title: string;
    description: string;
}

const starsDirectoryType: StarDirectoryInfo[] = [
    {
        path: 'aspects',
        title: 'Аспекты',
        description: 'Аспекты - это угловые отношения между планетами в городскопе, которые показывают, как планеты взаимодействуют и влияют друг на друга. Например, конъюнкция (когда планеты находятся в тесной связи друг с другом) может указывать на синергию или напряжение между их энергиями в зависимости от других факторов в городскопе.',
    },
    {
        path: 'astrological_transit',
        title: 'Астрологический транзит',
        description: 'Астрологический транзит - это периоды, когда текущее положение планет формирует значимые аспекты с планетами в натальной карте человека. Транзиты могут указывать на важные события, внутренние изменения или периоды роста и вызовов.',
    },
    {
        path: 'ascendant',
        title: 'Асцендент (восходящий знак)',
        description: 'Асцендент - это знак зодиака, который восходил на восточном горизонте в момент рождения человека. Он оказывает значительное влияние на внешнее выражение личности, внешний вид, стиль и первое впечатление, которое человек производит на других.',
    },
    {
        path: 'houses',
        title: 'Дома',
        description: 'В астрологии дома отражают различные жизненные сферы и области опыта. Например, первый дом ассоциируется с личной идентичностью и внешним восприятием, в то время как четвёртый дом связан с семьёй, корнями и домашним окружением.',
    },
    {
        path: 'zodiac_sign',
        title: 'Знак зодиака',
        description: 'Знак зодиака - это один из 12 сегментов, на которые делится эклиптика. Каждый знак ассоциирован с определённым созвездием и имеет свои уникальные качества и характеристики. Знаки зодиака играют ключевую роль в астрологии, так как они влияют на личностные черты, эмоции и поведение человека.',
    },
    {
        path: 'meridian',
        title: 'Меридиан (MC, Medium Coeli)',
        description: 'Меридиан или Срединное Небо - это точка в городскопе, которая представляет карьеру, статус и общественное положение человека. Она показывает амбиции, цели и то, как человек проявляется в общественной сфере.',
    },
    {
        path: 'natal_chart',
        title: 'Натальная карта',
        description: 'Натальная карта - это астрологическая карта, составленная для момента рождения человека, которая показывает положение планет, знаков зодиака и астрологических домов. Это карта используется для анализа личности, талантов, взаимоотношений и жизненного пути индивида.',
    },
    {
        path: 'planet',
        title: 'Планета',
        description: 'В астрологии каждая планета ассоциируется с определёнными аспектами личности и жизни. Например, Солнце представляет собой сущность, эго и волю человека, в то время как Луна отражает его эмоции, интуицию и подсознательные аспекты.',
    },
    {
        path: 'progressions',
        title: 'Прогрессии',
        description: 'Это один из методов прогнозирования в астрологии, который основан на идее, что внутренний рост и развитие человека отражается через символическое "прогрессирование" его натальной карты во времени. Например, каждый день после рождения может символизировать один год жизни человека.',
    },
    {
        path: 'retrograde',
        title: 'Ретроградность',
        description: 'Когда планета кажется движущейся назад в своём орбитальном пути с точки зрения Земли, она считается ретроградной. Это не физическое движение назад, а оптическая иллюзия. В астрологии ретроградные планеты ассоциируются с пересмотром, внутренними прозрениями и повторением прошлых ситуаций. Например, ретроградный Меркурий часто связывают с задержками и недопониманием в коммуникациях.',
    },
    {
        path: 'synastry',
        title: 'Синастрия',
        description: 'Астрологическая техника, используемая для анализа отношений между двуми людьми. Путём сравнения двух натальных карт можно исследовать их взаимное влияние, совместимость, вызовы и потенциал отношений.',
    }
]


const StarDirectoryInfo = () => {
    // Get Path 
    const { name } = useParams();

    // Get Path
    const [starInfo, setStarInfo] = useState<StarDirectoryInfo | null>(null);

    function getObjectByPath(): any {
        return starsDirectoryType.find(item => item.path === name);
    }

    useEffect(() => {
           if (name) {
            const starInfo = getObjectByPath();
            if (setStarInfo) {
                setStarInfo(starInfo);
            }
           }
    })

    return (
        <div className={style.star_directory_info}>
            {starInfo
                ? (
                    <div>
                        <h1>{starInfo.title}</h1>
                        <p>{starInfo.description}</p>
                    </div>
                )
                : (
                    <Spinner />
                )}
        </div>
    )
}

export default StarDirectoryInfo;