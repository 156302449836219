import { useDispatch, useSelector } from 'react-redux';
import type {TypedUseSelectorHook} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';


import blog from './blog/slice';
import auth from './auth/slice';
import chats from './chats/slice';
import blogs from './blogs/slice';
import packages from './packages/slice';
import payments from './payments/slice';
import promocode from './promocode/slice';
import myServices from './my-services/slice';

export const store = configureStore({
    reducer: {
        auth,
        blog,
        chats,
        blogs,
        packages,
        payments,
        promocode,
        myServices,
    },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;