import style from './Buttons.module.scss';


type PropsMainBtn = {
    text: string;
    disabled?: boolean;
}


const ButtonMain = ({ text, disabled }: PropsMainBtn) => {
    return (
        <button disabled={disabled} className={style.main_btn}>{text}</button>
    )
}

export default ButtonMain;