import style from './Package.module.scss';

// Ant Design
import { TeamOutlined } from '@ant-design/icons';

// Router
import { useNavigate } from 'react-router-dom';

// Telegram
import { MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { selectPackages } from '../../redux/packages/selectors';

// Components
import Spinner from '../../components/utils/spinner/Spinner';


const Package = () => {
    // Navigate
    const navigate = useNavigate();

    // Get Active Package
    const { activePackage } = useSelector(selectPackages);

    const handleBack = () => {
        navigate(-1);
    }

    const onClickBuy = (alias: string, verbose_name: string, price: number) => {
        navigate(`/home/service-pay/${alias}/${verbose_name}/${price}`);
    }

    const reformatDescription = (alias: string, description: string): string => {
        let desc = description.replace('[', '').replace(']', '').replaceAll("'", '');

        if (alias.includes('natal_chart')) {
            const desc_array = desc.split(/Тема \d+: /);
            desc = '';
            desc_array.forEach((el, idx) => {
                if (el.length > 0) {
                    // Проверяем, является ли последний символ запятой или точкой
                    if (el.trim().endsWith(',') || el.trim().endsWith('.') || el.trim().endsWith('.,')) {
                        el = el.slice(0, -2); // Удаляем последний символ
                    }
                    
                    desc += `Тема ${idx}: ${el.trim()}<br/></br>`;
                }
            })
        }
        return desc;
    }

    return (
        <div className={style.package}>
            <BackButton onClick={handleBack} />
            {activePackage
                ? (
                    <>
                        <h1 className={style.package__name}>
                            {activePackage.verbose_name}
                        </h1>
                        <div className={style.package__information}>
                            <div className={style.package__validity_period}>
                                <p>Срок действия:</p>
                                <p>{activePackage.period.value} месяца</p>
                            </div>
                            <div className={style.package__price}>
                                <p>Цена:</p>
                                <p>{activePackage.price} ₽</p>
                            </div>
                        </div>
                        <div className={style.package__description}>
                            <p dangerouslySetInnerHTML={{__html: reformatDescription(activePackage.alias, activePackage.detailed_description)}}></p>
                        </div>
                        <div className={style.package__form__communication}>
                            <h2>Формы общения с астрологом</h2>
                            {activePackage.services.map((s) => (
                                <div className={style.package__form__communication__info}>
                                    <div className={style.package__service}>
                                        <div className={style.package__service__image}>
                                            <TeamOutlined />
                                        </div>
                                        <p>{s.verbose_name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <MainButton
                            text='Купить'
                            color='#9696df'
                            onClick={() => onClickBuy(activePackage.alias, activePackage.verbose_name, activePackage.price)}
                        />
                    </>
                )
                : <Spinner />}
        </div>
    )
}

export default Package;